import { createRouter, /*createWebHistory */ createWebHashHistory } from '@ionic/vue-router';
import { Preferences } from '@capacitor/preferences';
import WiseSettings from '../WiseSettings';
import GymTabs from '@/WiseCore/components/GymTabs.vue'
import GolfTabs from '@/WiseEcom/components/GolfTabs.vue'
import ModeSelector from '@/components/ModeSelector.vue'
import reservationsRoutes from '@/WiseEcom/EcomReservation/views/routes.js'
import { defaultGuard } from './guard.js';

// ModeSelector for demo mode 
let defaultPath = {
  path: '/',
  component: ModeSelector,
}

if (WiseSettings.defaultPath) {
  defaultPath = WiseSettings.defaultPath;
}

const routes = [
  defaultPath,
  {
    path: '/gym/',
    component: GymTabs,
    children: [
      {
        path: '',
        redirect: '/gym/front'
      },
      {
        path: 'welcome',
        component: () => import('@/WiseGym/views/WelcomePage.vue'),
        beforeEnter: async (to, from, next) => {
            const { value: access_token } = await Preferences.get({ key: `access_token-${ WiseSettings.appId }` })
            const { value: terms } = await Preferences.get({ key: `terms_accepted-${ WiseSettings.appId }` });

            if (access_token && terms) {
                next('/gym/front')
            } else {
                next()
            }
        },
    },
    {
      path: 'front',
      component: () => import('@/WiseGym/views/GymFront.vue'),
      beforeEnter: async (to, from, next) => {
          const { value: access_token } = await Preferences.get({ key: `access_token-${ WiseSettings.appId }` })
          const { value: terms } = await Preferences.get({ key: `terms_accepted-${ WiseSettings.appId }` });

          if (!access_token || !terms) {
              next('/gym/welcome')
          } else {
              await defaultGuard(to, from, next)
          }
      },
  },
      {
        path: 'today',
        component: () => import('@/views/WiseTodayTab.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'today/article/:id',
        component: () => import('@/views/WiseArticle.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account',
        component: () => import('@/WiseCore/views/WiseAccountTab.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account/notifications',
        component: () => import('@/views/WiseNotifications.vue'),
        beforeEnter: defaultGuard
      },
      {
      path: 'account/notifications/oldnotifications',
      component: () => import('@/views/WiseOldNotifications.vue'),
      beforeEnter: defaultGuard
      },

      {
        path: 'account/helpCenter',
        component: () => import('@/views/WiseHelpCenter.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account/edit',
        component: () => import('@/views/WiseAccountEdit.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account/membership/contracts',
        component: () => import('@/views/WiseAccountContracts.vue'),
        beforeEnter: defaultGuard
      },

      {
        path: 'account/membership/contracts/contract/:id',
        component: () => import('@/views/WiseContract.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'fitness',
        component: () => import('@/views/WiseFitnessTab.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'fitness/event/:id',
        component: () => import('@/WiseGym/views/GymEvent.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'benefits',
        component: () => import('@/views/WiseBenefitsTab.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'services',
        component: () => import('@/views/GymServices.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'benefits/benefit/:id',
        component: () => import('@/views/WiseBenefit.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'register',
        component: () => import('@/WiseCore/views/WiseRegisterTab.vue')
      },
      {        
        path: 'account/membership',
        component: () => import('@/views/WiseMembership.vue'),
        beforeEnter: defaultGuard
      },
      {        
        path: 'account/membership/invoices',
        component: () => import('@/views/WiseInvoices.vue'),
        beforeEnter: defaultGuard
      },
      {        
        path: 'account/membership/invoices/invoice/:id',
        component: () => import('@/views/WiseInvoice.vue'),
        beforeEnter: defaultGuard
      },
      {
        //this is for development purposes
        path: 'account/admin',
        component: () => import('@/views/WiseAdminTab.vue'),
        beforeEnter: defaultGuard
      }
    ]
  },
  {
    path: '/golf/',
    component: GolfTabs,
    children: [
      {
        path: '',
        redirect: '/golf/front'
      },
      {
        path: 'register',
        component: () => import('@/WiseCore/views/WiseRegisterTab.vue')
      },
      {
        path: 'account',
        component: () => import('@/WiseEcom/EcomReservation/views/WiseAccountTab.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account/notifications',
        component: () => import('@/views/WiseNotifications.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'account/edit',
        component: () => import('@/views/WiseAccountEdit.vue'),
        beforeEnter: defaultGuard
      },
      {
        path: 'checkout',
        name: 'checkout',
        component: () => import('@/WiseEcom/EcomCheckout/views/EcomCheckout.vue'),
        beforeEnter: defaultGuard
      },
      /*
      {
        path: 'front',
        component: () => import('@/views/WiseFrontTab.vue')
      },
      */
      ...reservationsRoutes,
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router