<template>
	<ion-page>
		<ion-tabs>
			<ion-router-outlet></ion-router-outlet>
			<ion-tab-bar slot="bottom">
				<ion-tab-button tab="login" href="/gym/front">
					<i class="wn-icon home"></i>
					<ion-label  v-if="this.activeHost.useTabDescriptions">{{ loggedIn ? $t("tabs.frontPage") : $t('tabs.login')}}</ion-label>
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true && this.activeHost.useFitness" tab="fitness" href="/gym/fitness">
					<i class="wn-icon calendar"></i>
					<ion-label v-if="this.activeHost.useTabDescriptions">
						{{$t('tabs.calendar')}}
					</ion-label>
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true && this.activeHost.useServicesTab" tab="services" href="/gym/services">
					<i class="wn-icon heart"></i>
					<ion-label v-if="this.activeHost.useTabDescriptions">
						{{$t('tabs.services')}}
					</ion-label>
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true" tab="today" href="/gym/today">
					<i class="wn-icon notification"></i>
					<ion-label  v-if="this.activeHost.useTabDescriptions">{{$t('tabs.news')}}</ion-label>
				</ion-tab-button>

				<ion-tab-button v-if="loggedIn === true && this.activeHost.useBookingTab" tab="booking"
					@click="openBookingPage">
					<i class="wn-icon book-open"></i>
					<ion-label  v-if="this.activeHost.useTabDescriptions">{{$t('tabs.booking')}}</ion-label>
				</ion-tab-button>
				
				<ion-tab-button v-if="loggedIn === true && this.activeHost.useBenefits" tab="benefits" href="/gym/benefits">
					<i class="wn-icon benefits"></i>
					<ion-label  v-if="this.activeHost.useTabDescriptions">{{$t('tabs.benefits')}}</ion-label>
				</ion-tab-button>
				
				<ion-tab-button v-if="loggedIn === true" tab="account" href="/gym/account">
					<i class="wn-icon account"></i>
					<ion-label  v-if="this.activeHost.useTabDescriptions">{{$t('tabs.account')}}</ion-label>
					<ion-badge v-if="unreadNotifications && unreadNotifications.length > 0" color="primary">
					{{ unreadNotifications.length }}
					</ion-badge>
				</ion-tab-button>

			</ion-tab-bar>
		</ion-tabs>
	</ion-page>
</template>

<script>
import { IonTabBar, IonTabButton, IonTabs, IonRouterOutlet } from "@ionic/vue";
import { mapState, mapGetters } from "vuex";
import { usePushNotifications } from '@/helpers/usePushNotifications';

export default {
	name: "GymTabs",
	setup() {
		const { unreadNotifications, addNotification } = usePushNotifications();
		return {
			unreadNotifications,
			addNotification,
		}
	},
	components: { IonTabs, IonTabBar, IonTabButton, IonRouterOutlet },
	computed: {
		...mapState({loggedIn: state => state.user.loggedIn}),
		...mapGetters({ activeHost: "common/activeHost" }),
		...mapGetters('common', ['unreadNotifications']) 
	},
	methods: {
		openBookingPage() {
        window.open(this.activeHost.bookingUrl)
    }}
};
</script>

<style scoped>
ion-tab-button.hide {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
</style>