import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import { Device } from '@capacitor/device';
import WiseStore from '@/store';
//import settings from '@/WiseSettings.js';

// Used for to populate user state on initial load and reset user state on logout
const getUserDefaultState = () => {
	return {
		loggedIn: false,
		user: null,
		accessTokenValidTimestamp: null,
		deviceValidTimestamp: null,
		overrideHostUser: null,
	}
}

export default {
	namespaced: true,
	state: getUserDefaultState(),
	getters: {
		loggedIn: (state) => state.loggedIn,
		user: (state) => state.user,
		activeUser: (state) => {
			if (state.overrideHostUser !== null) {
				return {
					...state.user,
					...state.overrideHostUser,
				}
			}

			return state.user;
		},
	},
	mutations: {
		mutateUserStateToDefault(state) {
			Object.assign(state, getUserDefaultState())
		},
		mutateLoggedInUserData(state, user) {
			//console.log(`%cUser data set to:`, 'color: yellow; padding: 5px 0', user)
			state.user = user;
		},
		mutateLoggedIn(state, loggedIn) {
			if (loggedIn === false) {
				console.log(`%cLogged in set to:`, 'color: yellow; padding: 5px 0', false)
			}
			state.loggedIn = loggedIn;
		},
		mutateAccessTokenValidTimestamp(state, accessTokenValidTimestamp) {
			state.accessTokenValidTimestamp = accessTokenValidTimestamp;
		},
		mutateDeviceValidTimestamp(state, deviceValidTimestamp) {
			state.deviceValidTimestamp = deviceValidTimestamp
		},
		mutateOverrideHostUser(state, user) {
			state.overrideHostUser = user;
		},
		setUserImage(state, url) {
			if (state.user) {
				state.user.profileImgUrl = url;
				Preferences.set({
					key: `profileImageUrl`,
					value: url
				}).catch(err => console.error("Virhe tallennettaessa profiilikuvaa Preferencesiin", err));
			}
		}
	},
	actions: {
		async validateAccessTokenFromStorage({ dispatch, state, rootGetters, rootState, commit }) {
			const activeHost = rootGetters["common/activeHost"];
			const { value: accessToken } = await Preferences.get({ key: `access_token-${rootState.common.settings.appId}` });
			const { value: selectedHost } = await Preferences.get({ key: `selectedHost-${rootState.common.settings.appId}` })

			let legitToken = false;
			let user = null;
			// If no accessToken
			if (!accessToken || !selectedHost) {
				return Promise.reject('No access token found from local storage');
			}
			
			if (activeHost.sessionType) {
				axios.defaults.headers.common['x-session-type'] = activeHost.sessionType;
			}

			// Validate access token if last validation over 5min ago
			if ((Math.floor(Date.now() / 1000) - state.accessTokenValidTimestamp) < 300 && state.user.profileImgUrl !== undefined) {
				return true;
			}

			try {
				const { data } = await validateAccessToken(rootGetters, accessToken)
				
				legitToken = data.success;
				user = data.user;
			} catch (error) {
				console.error(error)
				return Promise.reject(error)
			}

			if (legitToken) {
				axios.defaults.headers.common['Authorization'] = `token ${ accessToken }`;

				commit('mutateAccessTokenValidTimestamp', Math.floor(Date.now() / 1000))
				commit('mutateLoggedIn', true)
				commit('mutateLoggedInUserData', user) // Populate user object with logged in user data
				
				if (user.profileImgUrl) {
					await Preferences.set({
						key: `profileImageUrl-${rootState.common.settings.appId}`,
						value: user.profileImgUrl
					});
				}
				if (rootState.common.settings.useGymFeatures) {
					// Update Door/Location data from server
					await dispatch('door/getDoorAndLocationDataFromServer', {}, {root: true})
				}
			} else {
				commit('mutateLoggedIn', false)
				commit('mutateLoggedInUserData', null)
			}

			return legitToken;
		},

		async validateUserDevice({ rootGetters, rootState, state, commit }, updateInfo = false) {
			if ((Math.floor(Date.now() / 1000) - state.deviceValidTimestamp) < 180) {
				return true;
			}
			const activeHost = rootGetters["common/activeHost"];
			const deviceUUID = await Device.getId();
			const args = {
				uuid: deviceUUID.identifier,
				appId: rootState.common.settings.appId,
				update: updateInfo
			}
			try {
				const data = await axios.post(`${activeHost.restUrl}validatedevice/`, args)
				console.log("validateUserDevice success: ", data)

				if (data.data.success) {
					commit('mutateDeviceValidTimestamp',  Math.floor(Date.now() / 1000))
					return true
				} else if (!data.data.success) {
					return false
				}
			} catch (error) {
				console.error("validateUserDeviceError: ",error)
				return Promise.reject(error)
			}
		},

		// Update user notification settings to DB
		async updateUserSettings( {state, rootState, rootGetters}, newSettings) {
			try {
				const deviceGetInfo = await Device.getInfo();
				const deviceGetId = await Device.getId();
				// Map deviceInfo values for backend
				const deviceInfo = {
					uuid: deviceGetId.identifier,
					manufacturer: deviceGetInfo.manufacturer,
					model: deviceGetInfo.model,
					platform: deviceGetInfo.operatingSystem,
					version: deviceGetInfo.osVersion
				}

				const settingsData = {
					appId: rootState.common.settings.appId,
					settings: {notifications: newSettings},
					user: state.user,
					device: deviceInfo,
					appVersion: rootState.common.settings.appVersion,
					binaryVersion: null
				}

				//console.log("Capacitor device plugins sending to server: ", settingsData)

				await axios.post(`${rootGetters["common/activeHost"].ajaxUrl}?updateusersettings=1&appauth=${rootGetters["common/activeHost"].appauth}`, settingsData)
			} catch (error) {
				console.error("Error on updateUserSettings():", error)
			}
		},

		async logIn({ rootGetters, commit, rootState }, loginFormData) {
			try {
				const activeHost = rootGetters['common/activeHost'];
				const { settings } = rootState.common;
				const headers = {};
				let loginUrl = `${ activeHost.ajaxUrl }?action=ajaxauthorize&appauth=${activeHost.appauth}`;

				if (activeHost.restUrl && activeHost.useRestLogin) {
					loginUrl = `${ activeHost.restUrl }/auth`;
				}
				
				if (activeHost.sessionType) {
					axios.defaults.headers.common['x-session-type'] = activeHost.sessionType;
				}
				
				const { data } = await axios.post(
					loginUrl, 
					loginFormData,
					{ headers }
				);
				axios.defaults.headers.common['Authorization'] = `token ${ data.access_token }`;

				await WiseStore.dispatch("common/getMobileAppSettings");
				document.body.classList.add(activeHost.className.toLowerCase())


				commit('mutateLoggedInUserData', data)
				commit('mutateLoggedIn', true)
				commit('mutateAccessTokenValidTimestamp', Math.floor(Date.now() / 1000))

				await Preferences.set({ key: `access_token-${ settings.appId }`, value: data.access_token });
				await Preferences.set({ key: `selectedHost-${ settings.appId }`, value: activeHost.name });

				if (loginFormData.saveCredentials) {
					console.log("Form data", loginFormData)
					await Preferences.set({ key: `username-${ settings.appId }`, value: loginFormData.username });
					await Preferences.set({ key: `password-${ settings.appId }`, value: loginFormData.password });
				} else {
						await Preferences.remove({ key: `username-${ settings.appId }` });
						await Preferences.remove({ key: `password-${ settings.appId }` });
				}
				
				return data;
			} catch (error) {
				if (error.response) {
					console.error(error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error(error)
					return Promise.reject(error)
				}
			}
		},

		async logOut({rootGetters, rootState, commit, dispatch }) {
			const activeHost = rootGetters["common/activeHost"];

			try {
				const url = `${ activeHost.restUrl }logout`
				await axios.post(url)
			} catch (error) {
				if (error.response) {
					console.error(error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error(error)
					return Promise.reject(error)
				}
			}
			const { settings } = rootState.common;

			// Reset User vuex module states
			commit('mutateUserStateToDefault')
			console.log(`%cVuex user module states reset`, 'color: yellow; padding: 5px 0')

			// Reset Gym common vuex module states
			commit("gym_common/mutateGymCommonStateToDefault", {}, {root: true})
			console.log(`%cVuex gym common module states reset`, 'color: yellow; padding: 5px 0')

			// Reset Door vuex module states
			commit("door/mutateDoorStateToDefault", {}, {root: true})
			console.log(`%cVuex door module states reset`, 'color: yellow; padding: 5px 0')

			// Reset common vuex module states
			commit("common/mutateCommonStateToDefault", {}, {root: true})
			console.log(`%cVuex common module states reset`, 'color: yellow; padding: 5px 0')
			
			// Get MobileAppSettings back from DB after common state reset
			dispatch("common/getMobileAppSettings", {}, {root: true});

			try {
				delete axios.defaults.headers.common.Authorization

				const userPreferences = await Preferences.keys()
				const skipReset = ['screenMode', 'selectedHost', 'selectedLocale', 'username', 'password', 'pushNotificationsToast', 'checked-system-notifications', 'notifications-settings','saveCredentials', 'lastNotificationDate', 'unreadNotificationIds' ]
				.map(key => `${ key }-${ settings.appId }`)
				
				userPreferences.keys.forEach(async (key) => {
					if (skipReset.includes(key) || key.includes('wise-subscribed-topics')) return;
					await Preferences.remove({ key })
				})

				if (settings.hosts.length > 1 && !settings.isBranded ) {
					Array.from(document.body.classList)
					.forEach(row => {
						if (row.includes(activeHost.className)) {
							document.body.classList.remove(row)
						}
					})
				}
				
				console.log(`%cLocal storage cleared!`, 'color: yellow; padding: 5px 0')
				console.log(`%cLogout success!`, 'color: lime; padding: 5px 0')
			} catch (error) {
				console.error("Error clearing local storage: ", error)
			}
		},

		async deleteUser({ rootGetters }) {
			try {
				const activeHost = rootGetters["common/activeHost"];
				const url = `${ activeHost.ajaxUrl }?appdeleteperson=1&appauth=${ activeHost.appauth }`;
			
				const { data } = await axios.post(url);
				
				return data;	

			} catch (error) {
				if (error.response) {
					console.error(error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error(error)
					return Promise.reject(error)
				}
			}
		},

		// special wisegolf endpoint for consenting user to another service provider
		async consentUser({ commit }, host) {
			
			try {
				let loginUrl = `${ host.restUrl }/app/signon/consent${ host.checkConsent ? '/?checkConsent=1' : '' }`;
				
				const { data } = await axios.post(loginUrl);

				if (data.user) {
					commit('mutateLoggedInUserData', data.user)
				}
				
				return data;
			} catch (error) {
				if (error.response) {
					console.error('wisegolf consent error: ', error.response.data);
					return Promise.reject(error.response.data)
				} else {
					console.error('wisegolf consent error: ', error)
					return Promise.reject(error)
				}
			}
		}
	}
}

const validateAccessToken = async (rootGetters, accessToken) => {
	try {
		return await axios.get(`${rootGetters["common/activeHost"].ajaxUrl}?getsessiondata=1&appauth=${rootGetters["common/activeHost"].appauth}`,
			{
				headers: { 'Authorization': `token ${accessToken}` }
			});
	} catch (error) {
		console.error(error)
	}
}
