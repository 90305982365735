<template>
	<div class="height-100">
		<ion-header>
			<ion-toolbar color="primary">
				<ion-title>{{ notificationData.messageTitle }}</ion-title>
				<ion-buttons slot="end">
					<ion-button @click="closeModal()">{{$t('close')}}</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>
		<ion-content class="ion-padding ion-text-center">
			<p v-html="notificationData.messageBody"></p>
			<p v-if="notificationData.payload">
				{{ notificationData.payload.linkText }} <br /> <br />
				<a v-bind:href="notificationData.payload.linkUrl" target="_blank">
					{{ notificationData.payload.linkUrl }}
				</a>
			</p>
			<br />
			{{ notificationData.dateCreated }}
		</ion-content>
	</div>
</template>

<script>
import { modalController } from "@ionic/vue";

export default {
	name: "WiseNotificationModal",
	props: {
		notificationData: Object
	},
  methods: {
    closeModal() {
      modalController.dismiss();
    }
  }
};
</script>