import axios from 'axios'
import store from '../store/index.js';
import { formatCurrency } from './services/WiseCurrency.js'
import { dateFormat, formatDateTime, getDateFormat } from './services/WiseDates.js'
import { _locale } from './services/WiseLocale';
import { wiseAlert } from './services/WiseAlert.js';
import WiseBillingOperators from './services/WiseBillingOperators.js';
import ModalWrapper from './components/ModalWrapper.vue'

let CURRENCY_SYMBOL = '€'; // default, what would be the 'correct' way to change this

function changeCurrencySymbol(symbol) {
	CURRENCY_SYMBOL = symbol;
}

const components = {
	ModalWrapper,
}

export default {
	install(app) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		});

		// WiseNetwork globals
		app.config.globalProperties.$_wiseAlert = wiseAlert;
		app.config.globalProperties.$_formatCurrency = formatCurrency;
        app.config.globalProperties.$_CURRENCY_SYMBOL = CURRENCY_SYMBOL;
		app.config.globalProperties.$_changeCurrencySymbol = changeCurrencySymbol;
        app.config.globalProperties.$_formatDateTime = formatDateTime;
        app.config.globalProperties.$_dateFormat = dateFormat;
        app.config.globalProperties.$_getDateFormat = getDateFormat;
		app.config.globalProperties.$_http = axios;
		app.config.globalProperties.$_locale = _locale;
		app.config.globalProperties.$_billingOperators = WiseBillingOperators.map((operator) => {
            operator.text = `${operator.text} (${operator.value})`
            
            return operator
        })
		app.config.globalProperties.$_getCountries = async () => {
			const userLocale = store.getters['common/userLocale']
			let countries = {};
			
			if(userLocale.split('-')[0] === 'fi') {
				countries = await import('@/countries/countries-fi.json');
			} else {
				countries = await import('@/countries/countries-en.json');
			}
			// sort alphabetically by values
			return Object.keys(countries.default).map(key => {
				return { 
					code: key, 
					id: key,
					text: countries.default[key] 
				}
			})
			.sort((a, b) => a.text > b.text ? 1 : -1)
		}
	}
};
