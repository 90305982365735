// WiseCore vuex modules
import user from "@/WiseCore/WiseStore/modules/user";
import common from "@/WiseCore/WiseStore/modules/common";
import door from "@/WiseCore/WiseStore/modules/door";

// WiseCore components
import FrontLogin from "@/WiseCore/components/WiseFrontLogin.vue";
import LocaleSelector from "@/WiseCore/components/WiseLocaleSelector.vue";
import LoginForm from "@/WiseCore/components/WiseLoginForm.vue";
import RegisterForm from "@/WiseCore/components/WiseRegisterForm.vue";
import WiseError from "@/WiseCore/components/WiseError.vue";
import WisePage from "@/WiseCore/components/WisePage.vue";
import WiseBackButton from "@/WiseCore/components/WiseBackButton.vue";
import WiseFooter from "@/WiseCore/components/WiseFooter.vue";
import WiseCountrySelector from "@/WiseCore/components/WiseCountrySelector.vue";
import WiseInfocard from '@/WiseCore/components/WiseInfocard.vue'

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.min.css';

import {
	IonPage,
	IonContent,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonSpinner,
	IonButton,
	IonBackButton,
	IonButtons,
	IonNote,
	IonItem,
	IonRadio,
	IonRadioGroup,
	IonList,
	IonThumbnail,
	IonLabel,
	IonSlides,
	IonSlide,
	IonText,
	IonImg,
	IonIcon,
	IonToggle,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonChip,
	IonGrid,
	IonRow,
	IonCol,
	IonListHeader,
	IonDatetime,
	IonCheckbox,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	IonTextarea,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonCardContent,
	IonModal,
	IonPopover,
	IonAvatar,
	IonAccordion,
	IonAccordionGroup,
	IonFab,
	IonFabButton,
	IonBadge
} from '@ionic/vue';

const components = {
	IonPage,
	IonContent,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonSpinner,
	IonButton,
	IonBackButton,
	IonButtons,
	IonNote,
	IonItem,
	IonList,
	IonThumbnail,
	IonLabel,
	IonSlides,
	IonSlide,
	IonText,
	IonImg,
	IonIcon,
	IonToggle,
	IonInput,
	IonSelect,
	IonSelectOption,
	IonRadio,
	IonRadioGroup,
	IonChip,
	IonGrid,
	IonRow,
	IonCol,
	IonListHeader,
	IonDatetime,
	IonCheckbox,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	IonTextarea,
	IonCard,
	IonCardHeader,
	IonCardTitle,
	IonCardSubtitle,
	IonCardContent,
	IonModal,
	IonPopover,
	IonAvatar,
	IonAccordion,
	IonAccordionGroup,
	IonFab,
	IonFabButton,
	IonBadge,
	FrontLogin,
	LocaleSelector,
	LoginForm,
	RegisterForm,
	WiseBackButton,
	WiseError,
	VueJsonPretty,
	Swiper,
	SwiperSlide,
	WisePage,
	WiseFooter,
	WiseCountrySelector,
	WiseInfocard
}

export default {
	install(app) {
		Object.keys(components).forEach((name) => {
			const component = components[name];
			app.component(name, component);
		});

		if (typeof app.config.globalProperties.$store !== 'undefined') {
			app.config.globalProperties.$store.registerModule('user', user);
			app.config.globalProperties.$store.registerModule('common', common);
			app.config.globalProperties.$store.registerModule('door', door);
		}
	}
};
